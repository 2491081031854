export const BASE_URL = "/analytics-admin";

export const MFE_ROUTES = {
  HOME: `${BASE_URL}`,
  CREATE_WORKSPACE: `${BASE_URL}/workspace/create`,
  WORKSPACE: `${BASE_URL}/workspace/:workspaceId`,
  THEME_DETAILS: `${BASE_URL}/workspace/:workspaceId/:themeId`,
  REPORT_DETAILS: `${BASE_URL}/workspace/:workspaceId/:themeId/:reportId`,
  REPORTS_MFE_MAIN_PAGE: "/reports",
};

export const GRAY_COLOR = "rgba(20, 20, 20, 0.56)";

export const WORKSPACE_GROUP_SCOPE = {
  WORKSPACE_GROUP: ["PartnerDashboard.Workspace admin"],
};

export const TRACKINGID_REGEX = /^[A-Z0-9]+(_[A-Z0-9]+)*$/;

export const EU_CLUSTER_COUNTRIES = ["PH", "ID"];
export const EU_CLUSTER = "Global_EU";
export const BFF_URL = "/partner-analytics-business-service";

export const ERROR_COLOR = "$semanticErrorText";

export enum ENDPOINTS {
  WORKSPACES = "/workspaces",
  REPORTS = "/reports",
}

export const DEBOUNCE_DELAY = 500;
