import { useLogService } from "admin-portal-shared-services";

interface AppErrorBoundaryProps {
  err: Error;
}

export function AppErrorBoundary({ err }: AppErrorBoundaryProps): null {
  useLogService().error(err);
  return null;
}
